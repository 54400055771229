import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form, Table } from "react-bootstrap";
import jsQR from "jsqr";
import { HashFile } from "../components/HashFile";
import EXIF from "exif-js";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import Select from "react-select";
import { DateRangePicker } from "react-date-range";
import { toast } from "react-toastify";
import axios from "axios";
import moment from "moment";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import readJPG from "read-jpg";
import Resizer from "react-image-file-resizer";
import isEmpty from "../../../Validations/is-empty";
import { isSameDay } from "date-fns";
import ExifReader from "exifreader";

var URL = process.env.REACT_APP_API_URL;
var start_date_str = "";
var end_date_str = "";
var previous_date = moment().subtract(10, "days");

class Uploader extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      files: [],
      data: [],
      uploading: false,
      open_modal: false,
      fields: [],
      field_id: "0",
      field_config_id: "0",
      field_name: "0",
      start_date: new Date(),
      end_date: new Date(),
      list: [],
      sizes: [],
      images_fields: [],
      update_required: false,
      show_resume_button: false,
      fields_to_be_updated: [],
      total_files: 0,
      uploaded_files: 0,
    };
  }

  OpenDialog = (open_modal) => {
    // if (!open_modal) {
    //   this.myRef.current.value = "";
    //   this.setState({ open_modal, files: [], data: [] });
    // }
    this.setState({ open_modal });
  };

  UploadFiles = async (e) => {
    var files = e.target.files;
    if (!isEmpty(localStorage.getItem("token"))) {
      var proceed = await this.CheckGDriveEmail();
      if (proceed) {
        console.log("Proceeding");
        await this.MarkPreviousSessionFiles();
        var data = this.state.data;
        for (var i = 0; i < files.length; i++) {
          if (i === 0) {
            data = [{ name: files[i].name, status: "pending" }];
          } else {
            data.push({ name: files[i].name, status: "pending" });
          }
        }
        this.setState(
          { data, open_modal: true, files, uploading: true },
          async () => {
            files = this.state.files;
            var i = 0;
            for (const file of files) {
              data = this.state.data;
              data[i].status = "Checking file name in DB...";
              await this.FileCheck(data, file, i);
              i++;
            }
            // this.FilterFiles();
            // if (!this.state.update_required) {
            //   if (isEmpty(this.state.data)) {
            //     toast.error("No files to upload!");
            //     this.setState({ open_modal: false });
            //   } else {
            //     toast.success("Uploading files to drive!");
            //     await this.UploadFilesToDrive();
            //   }
            // } else {
            //   toast.error(
            //     "Please update end date of the highlighted fields and then click 'Resume Button'!"
            //   );
            //   this.setState({ open_modal: false, show_resume_button: true });
            // }
          }
        );
        this.setState({ data, uploading: false, list: [] });
      }
    } else {
      toast.error(
        "Please reload the page and login before you upload any file !"
      );
    }
  };

  UploadFilesServer = async (e) => {
    var files = e.target.files;
    console.log("uploading to server", files);
    var data = this.state.data;
    for (var i = 0; i < files.length; i++) {
      if (i === 0) {
        data = [{ name: files[i].name, status: "pending" }];
      } else {
        data.push({ name: files[i].name, status: "pending" });
      }
    }
    this.setState(
      {
        data,
        open_modal: true,
        files,
        uploading: true,
        total_files: files.length,
        uploaded_files: 0,
      },
      async () => {
        files = this.state.files;
        var i = 0;
        for (const file of files) {
          console.log("Checking file name in DB...", file);
          data = this.state.data;
          data[i].status = "Checking file name in DB...";
          await this.FileCheck(data, file, i);
          i++;
        }
        await this.FilterFiles();
        if (!this.state.update_required) {
          if (isEmpty(this.state.data)) {
            toast.error("No files to upload!");
            this.setState({ open_modal: false });
          } else {
            console.log("Uploading files to server!");
            toast.success("Uploading files to drive!");
            // await this.UploadFilesToDrive();
            await this.UploadFilesToServer();
          }
        } else {
          toast.error(
            "Please update end date of the highlighted fields and then click 'Resume Button'!"
          );
          this.setState({ open_modal: true, show_resume_button: true });
        }
      }
    );
    this.setState({ data, uploading: false, list: [] });
  };

  FileCheck = async (data, file, i) => {
    this.setState({ data });
    var index = i;
    var hash = "";
    var QR = "";
    var QRExistance = false;
    data = this.state.data;
    let proceed;
    proceed = await this.CheckFile("name", file.name);

    var exif = await this.readExif(file);

    console.log('exif', exif);
    if (!exif) {
      var date = file.name.split("_")[0];
      if (!moment(date, "DD-MM-YYYY").isValid()) {
        data[index].status = "No exif found!";
        this.setState({ data: [...data] });
        return;
      }

      exif = {
        date: moment(date, "DD-MM-YYYY").format("YYYY-MM-DD"),
      };
    }

    if (proceed) {
      data = this.state.data;
      data[index].status = "File doest not exixst! Checking md5 now ...";
      this.setState({ data: [...data] });
      hash = await HashFile(file);
      let proceed1;
      proceed1 = await this.CheckFileHash(file.name, hash);
      if (proceed1) {
        data[index].status = "MD5 doest not exists! Reading QR...";
        this.setState({ data: [...data] });
        QR = exif.QR ?? (await this.ReadQR(file));
        // QR = await this.ReadQR(file);
        if (!isEmpty(QR)) {
          if (this.GetFieldFromQR(QR)) {
            QRExistance = await this.CheckFileQR(QR, exif.date);
            if (QRExistance) {
              data[index].status = "Duplicate Content!";
              this.setState({ data: [...data] });
            } else {
              this.AddFileToList(
                file.name,
                hash,
                QR,
                file,
                exif.date,
                "Completed!"
              );
              data[index].status = "Saved to upload list!";
              this.setState({ data: [...data] });
            }
          } else {
            data[index].status = "Image field does not belong to you!";
            this.setState({ data: [...data] });
          }
        } else {
          this.AddFileToList(
            file.name,
            hash,
            QR,
            file,
            exif.date,
            "Completed | QR not detected!"
          );
          data[index].status = "Saved to upload list| QR not detected!";
          this.setState({ data: [...data] });
        }
      } else {
        data[index].status = "File already exixsts | MD5 Hash!";
        this.setState({ data: [...data] });
      }
    } else {
      data = this.state.data;
      data[index].status = "File already exixst! Checking md5 now ...";
      this.setState({ data: [...data] });
      hash = await HashFile(file);
      let proceed1;
      proceed1 = await this.CheckFileHash(file.name, hash);
      if (proceed1) {
        data[index].status = "MD5 doest not exists! Reading QR...";
        this.setState({ data: [...data] });
        QR = exif.QR ?? (await this.ReadQR(file));
        // QR = await this.ReadQR(file);
        if (!isEmpty(QR)) {
          if (this.GetFieldFromQR(QR)) {
            QRExistance = await this.CheckFileQR(QR, exif.date);
            if (QRExistance) {
              data[index].status = "Duplicate Content!";
              this.setState({ data: [...data] });
            } else {
              this.AddFileToList(
                file.name,
                hash,
                QR,
                file,
                exif.date,
                "Completed!"
              );
              data[index].status = "Saved to upload list!";
              this.setState({ data: [...data] });
            }
          } else {
            data[index].status = "Image field does not belong to you!";
            this.setState({ data: [...data] });
          }
        } else {
          this.AddFileToList(
            file.name,
            hash,
            QR,
            file,
            exif.date,
            "Completed | QR not detected!"
          );
          data[index].status = "Saved to upload list| QR not detected!";
          this.setState({ data: [...data] });
        }
      } else {
        data[index].status = "File already exixsts | MD5 Hash!";
        this.setState({ data: [...data] });
      }
    }
  };

  readExif = async (file) => {
    return new Promise(async (resolve) => {
      const meta = await ExifReader.load(file);
      if ((!meta.ImageDescription && !meta.UserComment && meta.ImageDescription.description.trim() != "") || !meta.DateTimeOriginal) {
        resolve(null);
        return;
      }

      var QR = null;
      if (meta.UserComment && meta.UserComment.value) {
        QR = String.fromCharCode(...meta.UserComment.value);
      }else if (meta.ImageDescription && meta.ImageDescription.description) {
        QR = meta.ImageDescription.description.trim();
      }

      if (meta.DateTimeOriginal) {
        var [date, time] = meta.DateTimeOriginal.description.split(" ");
        date = date.replace(/:/g, "-");
        resolve({
          QR: QR,
          date: date,
        });
      } else {
        resolve({
          QR: QR,
        });
      }
    });
  };

  CreateFileName = (name, field_id) => {
    var field = this.state.fields.filter((field) => field.name === field_id);
    // console.log(field);
    var nameArray = name.split(".");
    return (
      nameArray[0] +
      "_" +
      field[0].start_date.slice(8, 10) +
      field[0].start_date.slice(5, 7) +
      "_" +
      field[0].end_date.slice(8, 10) +
      field[0].end_date.slice(5, 7) +
      "_" +
      field_id +
      "." +
      nameArray[1]
    );
  };

  UploadFile = async (name, md5, QR, file, data, index) => {
    let uploaded;
    var field_id = this.GetFieldIDFromQR(QR);
    var altered_name = "";
    if (!isEmpty(field_id)) {
      altered_name = this.CreateFileName(name, field_id);
    } else {
      altered_name = name;
    }
    uploaded = await this.CreateFile(
      {
        name,
        md5,
        QR,
        drive_link: "",
        file_id: "",
        altered_name,
        field_id,
      },
      file,
      data,
      index
    );
    if (uploaded) {
      data[index].status = data[index].success_status;
    } else {
      data[index].status = " Upload error!";
    }
    this.setState({ data: [...data] });
  };

  UploadFileToServer = async (name, md5, QR, file, date, data, index) => {
    let uploaded;
    var field_id = this.GetFieldIDFromQR(QR);
    var altered_name = "";
    if (!isEmpty(field_id)) {
      altered_name = this.CreateFileName(name, field_id);
    } else {
      altered_name = name;
    }
    // console.log('UploadFileToServer', name, md5, QR, file, data, index);
    uploaded = await this.CreateFileServer(
      {
        name,
        md5,
        QR,
        date,
        drive_link: "",
        file_id: "",
        altered_name,
        field_id,
      },
      file,
      data,
      index
    );
    if (uploaded) {
      data[index].status = "Uploaded ✔️";
    } else {
      data[index].status = " Upload error!";
    }
    this.setState({ data: [...data] });
  };

  AddFileToList = (name, md5, QR, file, date, success_status) => {
    var file_data = {
      name,
      md5,
      QR,
      file,
      success_status,
      date,
      status: "pending",
    };
    var list = this.state.list ?? [];
    list.push(file_data);
    this.setState({ list: [...list] });
  };

  UploadFilesToDrive = async () => {
    var files = this.state.data;
    var i = 0;
    for (const file of files) {
      console.log(file);
      var data = this.state.data;
      data[i].status = "Uploading to drive";
      this.setState({ data: [...data] });
      await this.UploadFile(
        data[i].name,
        data[i].md5,
        data[i].QR,
        data[i].file,
        data,
        i
      );
      i++;
    }
    toast.success("Files uploaded successfully!");
    this.setState({ open_modal: false, data: [] });
  };

  UploadFilesToServer = async () => {
    var files = this.state.data;
    var i = 0;
    for (const file of files) {
      // console.log(file);
      var data = this.state.data;
      data[i].status = "Uploading to server";
      this.setState({ data: [...data] });
      await this.UploadFileToServer(
        data[i].name,
        data[i].md5,
        data[i].QR,
        data[i].file,
        data[i].date,
        data,
        i
      );
      data[i].status = "Uploaded ✔️";
      this.setState({ data: [...data] });
      i++;
    }
    toast.success("Files uploaded successfully!");
    this.setState({ data: [] });
  };

  FilterFiles = () => {
    var files_list = this.state.list;
    var filtered_files = [];
    var i = 0;
    files_list.forEach((file) => {
      if (i === 0) {
        if (isEmpty(file.QR)) {
          filtered_files.push(file);
        } else {
          this.CheckFieldEndDateFromFile(file);
          filtered_files.push(file);
        }
      } else {
        if (isEmpty(file.QR)) {
          filtered_files.push(file);
        } else {
          var result = filtered_files.find((obj) => obj.QR === file.QR);
          if (result && isSameDay(new Date(result.date), new Date(file.date))) {
            var index = filtered_files.findIndex((obj) => obj.QR === file.QR);
            filtered_files.splice(index, 1);
            filtered_files.push(file);
          } else {
            this.CheckFieldEndDateFromFile(file);
            filtered_files.push(file);
          }
        }
      }
      i++;
    });
    this.setState({ data: filtered_files });
  };

  dataURLtoFile = function(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  detect = (source, file) => {
    return new Promise((resolve) => {
      var canvas = document.getElementById("canvas");
      var ctx = canvas.getContext("2d");
      var offCanvas;
      var usingOffscreenCanvas = true;

      function getOffCtx2d(width, height) {
        if (usingOffscreenCanvas) {
          if (
            !offCanvas ||
            offCanvas.width !== width ||
            offCanvas.height !== height
          ) {
            // Only resizing the canvas caused Chromium to become progressively slower
            offCanvas = new OffscreenCanvas(width, height);
          }

          return offCanvas.getContext("2d");
        }
      }

      canvas.width = source.width;
      canvas.height = source.height;
      if (canvas.height && canvas.width) {
        const offCtx = getOffCtx2d(canvas.width, canvas.height) || ctx;

        offCtx.drawImage(source, 0, 0);
        var imageData = offCtx.getImageData(0, 0, canvas.width, canvas.height);
        window.zbarWasm.scanImageData(imageData).then((symbols) => {
          symbols.forEach((symbol) => {
            var lastPoint = symbol.points[symbol.points.length - 1];
            ctx.moveTo(lastPoint.x, lastPoint.y);
            symbol.points.forEach((point) => ctx.lineTo(point.x, point.y));

            ctx.lineWidth = Math.max(
              Math.min(canvas.height, canvas.width) / 100,
              1
            );
            ctx.strokeStyle = "#00e00060";
            ctx.stroke();
          });

          symbols.forEach((s) => (s.rawValue = s.decode("UTF-8")));
          symbols.forEach((s) => {
            delete s.type;
            delete s.data;
            delete s.points;
            delete s.time;
            delete s.cacheCount;
          });
          var results = JSON.stringify(symbols, null, 2);
          resolve(results);
        });
      } else {
        resolve("");
      }
    });
  };

  ReadQR = async (file) => {
    return new Promise(async (resolve, reject) => {
      var el = document.getElementById("img");
      el.src = window.URL.createObjectURL(file);
      setTimeout(async () => {
        await el.decode();
        var results = await this.detect(el, file);
        var rawValueQRCode = "";
        var resultCode = JSON.parse(results);
        if (
          resultCode !== null &&
          resultCode !== undefined &&
          resultCode !== "" &&
          !isEmpty(resultCode)
        ) {
          // console.log(resultCode[0].rawValue);
          rawValueQRCode = resultCode[0].rawValue;
          resolve(rawValueQRCode);
        } else {
          for (var i = 0; i < this.state.sizes.length; i++) {
            // console.log(this.state.sizes[i].size);
            var ratio = el.width / el.height;
            var resizedData = await this.ResizeFile(
              file,
              ratio,
              this.state.sizes[i].size
            );
            var resizedFile = this.dataURLtoFile(resizedData, file.name);
            el = document.getElementById("img");
            el.src = window.URL.createObjectURL(resizedFile);
            await el.decode();
            results = await this.detect(el, resizedFile);
            rawValueQRCode = "";
            resultCode = JSON.parse(results);
            if (
              resultCode !== null &&
              resultCode !== undefined &&
              resultCode !== "" &&
              !isEmpty(resultCode)
            ) {
              // console.log(resultCode[0].rawValue);
              rawValueQRCode = resultCode[0].rawValue;
              resolve(rawValueQRCode);
              break;
            } else {
              if (i === this.state.sizes.length - 1) {
                resolve("");
              }
            }
          }
        }
      }, 100);
    });
  };

  ReadQRofResizedJPG = async (file) => {
    return new Promise(async (resolve, reject) => {
      let fileReader = new FileReader();
      await fileReader.readAsArrayBuffer(file);
      fileReader.onload = async (event) => {
        const result = await readJPG({ data: event.target.result });
        var QR_DATA = jsQR(result.pixels, result.width, result.height, "");
        // console.log("QR", QR_DATA);
        if (!isEmpty(QR_DATA)) {
          resolve(QR_DATA.data);
        } else {
          resolve("");
        }
      };
    });
  };

  Base64toFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  ResizeFile = (file, ratio, size) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        Math.ceil(size * ratio),
        size,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64",
        Math.ceil(size * ratio),
        size
      );
    });

  CheckFile = async (type, value) => {
    try {
      var response = await axios.get(
        `${URL}/images-data/get-file-data?type=${type}&value=${value}`
      );
      if (!response.data?.found) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  };

  CheckFileHash = async (name, md5) => {
    try {
      var res = await axios.get(
        `${URL}/images-data/check-file-hash?name=${name}&md5=${md5}`
      );
      if (!res.data?.found) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  };

  getBase64 = (file, contentType) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        var image = reader.result.split(",");
        var sliceSize = 512;
        const byteCharacters = window.atob(image[1]);
        const byteArrays = [];

        for (
          let offset = 0;
          offset < byteCharacters.length;
          offset += sliceSize
        ) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);

          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        resolve(blob);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  getBase64Full = (file, contentType) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        var image = reader.result;
        var sliceSize = 512;
        const byteCharacters = window.atob(image);
        const byteArrays = [];

        for (
          let offset = 0;
          offset < byteCharacters.length;
          offset += sliceSize
        ) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);

          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        resolve(blob);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  CreateFile = async (data, file, state_data, index) => {
    var token = "Bearer " + localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      this.getBase64(file, file.type)
        .then((blob) => {
          var folder_id = localStorage.getItem("folder_id");
          var metadata = { name: data.altered_name, parents: [folder_id] };
          const fd = new FormData();
          fd.append(
            "metadata",
            new Blob([JSON.stringify(metadata)], { type: "application/json" })
          );
          fd.append("file", blob);
          fetch(
            "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart",
            {
              method: "POST",
              headers: {
                Authorization: token,
              },
              body: fd,
            }
          )
            .then((response1) => response1.json())
            .then((jsonResp1) => {
              state_data[index].status =
                "File uploaded! Generating permissions...";
              this.setState({ data: [...state_data] });
              fetch(
                `https://www.googleapis.com/drive/v2/files/${jsonResp1.id}/permissions`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                  },

                  body: JSON.stringify({ role: "reader", type: "anyone" }),
                }
              )
                .then((response2) => response2.json())
                .then((jsonResp2) => {
                  state_data[index].status =
                    "Permission Generated! Getting link...";
                  this.setState({ data: [...state_data] });
                  fetch(
                    `https://www.googleapis.com/drive/v2/files/${jsonResp1.id}`,
                    {
                      method: "GET",
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                      },
                      params: {
                        fields: "webContentLink",
                      },
                    }
                  )
                    .then((response3) => response3.json())
                    .then(async (jsonResp3) => {
                      state_data[index].status =
                        "Permission Generated! Getting link...";
                      this.setState({ data: [...state_data] });
                      state_data[index].status = "Adding image in Database...";
                      this.setState({ data: [...state_data] });
                      data.drive_link = jsonResp3.webContentLink;
                      data.file_id = jsonResp1.id;
                      await axios
                        .post(`${URL}/images-data`, data)
                        .then(async (res3) => {
                          resolve(true);
                        })
                        .catch((err3) => {
                          reject(err3);
                        });
                    })
                    .catch((err4) => {
                      reject(err4);
                    });
                })
                .catch((err5) => {
                  reject(err5);
                });
            })
            .catch((err6) => {
              reject(err6);
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  CreateFileServer = async (data, file, state_data, index) => {
    var token = "Bearer " + localStorage.getItem("token");
    // don't know why we need to get base64...just increases size right? hmm.....
    // upload to server directly using axios post
    // console.log('CreateFileServer', data, file, state_data, index);
    let formData = new FormData();
    formData.append("file", file);
    formData.append("altered_name", data.altered_name);
    formData.append("md5", data.md5);
    formData.append("QR", data.QR);
    formData.append("date", data.date);
    formData.append("field_id", data.field_id);
    formData.append("name", data.name);

    axios
      .post(
        `${URL}/images-data/upload`,
        formData
        // {
        //   data,
        //   file,
        // },
        // {
        //   headers: {
        //     Authorization: token,
        //   }
        // }
      )
      .then((res) => {
        state_data[index].status = "Uploaded into the server ✔️";
        var upload_count = this.state.uploaded_files + 1;
        this.setState({ data: [...state_data], uploaded_files: upload_count });
        // data.drive_link = res.data.data.webContentLink;
        // data.file_id = res.data.data.id;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  CheckFileQR = async (QR, date) => {
    try {
      var res = await axios.post(`${URL}/images-data/check-file-QR`, {
        QR,
        date,
      });
      if (res.data?.found) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  };

  MarkPreviousSessionFiles = async () => {
    let bool;
    await axios
      .post(`${URL}/images-data/mark-previous-session-files`)
      .then((res) => {
        bool = true;
      })
      .catch((err) => {
        bool = false;
      });
    return bool;
  };

  FieldChanges = (val, event) => {
    this.setState({
      field_id: val.id + "",
      field_name: val.name,
      start_date: new Date(val.start_date),
      end_date: new Date(val.end_date),
      field_config_id: val.field_config_id,
    });
  };

  GetFields = async () => {
    await axios
      .get(`${URL}/field-config/get-fields-of-a-user`)
      .then(async (res) => {
        var records = [{ id: "0", name: "-- Select Field --" }];
        for (var i = 0; i < res.data.data.length; i++) {
          records.push({
            id: res.data.data[i].id + "",
            name: res.data.data[i].uid,
            start_date: res.data.data[i].field_config.start_date,
            end_date: res.data.data[i].field_config.end_date,
            field_config_id: res.data.data[i].field_config.id,
          });
        }
        await this.setState({
          fields: records,
        });
      });
  };

  GetSizes = async () => {
    axios.get(`${URL}/size?all=true`).then(async (res) => {
      this.setState({
        sizes: res.data.data,
      });
    });
  };

  updateDate = async () => {
    var start_date =
      this?.state?.start_date.getFullYear() +
      "-" +
      (Number(this?.state?.start_date.getMonth()) + 1) +
      "-" +
      this?.state?.start_date.getDate();
    var end_date =
      this?.state?.end_date.getFullYear() +
      "-" +
      (Number(this?.state?.end_date.getMonth()) + 1) +
      "-" +
      this?.state?.end_date.getDate();
    toast.success("Updating records!");
    axios
      .put(`${URL}/field-config/${this.state.field_config_id}`, {
        start_date,
        end_date,
      })
      .then((res) => {
        toast.success("Record updated successfully!");
        this.setState({
          field_id: "0",
          field_name: "0",
          start_date: new Date(),
          end_date: new Date(),
        });
        this.GetFields();
      })
      .catch((err) => {
        toast.error("Error occurred!");
      });
  };

  GoogleLogin = async () => {
    window.gapi.load("client:auth2", function() {
      window.gapi.auth2.init({
        client_id:
          "123800962898-l8jujgq9a37odvlnc248qin4o850mko2.apps.googleusercontent.com",
      });

      window.gapi.auth2
        .getAuthInstance()
        .signIn({
          scope:
            "https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/drive.appdata https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.metadata https://www.googleapis.com/auth/drive.metadata.readonly https://www.googleapis.com/auth/drive.photos.readonly https://www.googleapis.com/auth/drive.readonly",
        })
        .then(
          function(res) {
            localStorage.setItem(
              "google_drive_email",
              window.gapi.auth2
                .getAuthInstance()
                .currentUser.get()
                .getBasicProfile()
                .getEmail()
            );
            window.gapi.client.setApiKey(
              "AIzaSyCtbWuNtotohQHT3T0IUISk7E7inNNcqTg"
            );
            window.gapi.client
              .load(
                "https://content.googleapis.com/discovery/v1/apis/drive/v3/rest"
              )
              .then(
                function() {
                  localStorage.setItem(
                    "token",
                    window.gapi.auth.getToken().access_token
                  );
                  var date =
                    moment().year() +
                    "-" +
                    (moment().month() + 1) +
                    "-" +
                    moment().date();
                  window.gapi.client.drive.files
                    .list({
                      q: "mimeType='application/vnd.google-apps.folder'",
                    })
                    .then(
                      function(response) {
                        var folder = response.result.files.find(
                          (obj) => obj.name === "ui_images"
                        );
                        if (!isEmpty(folder)) {
                          localStorage.setItem("root_folder", folder.id);
                          window.gapi.client.drive.files
                            .list({
                              q:
                                "mimeType='application/vnd.google-apps.folder'",
                            })
                            .then(
                              function(response) {
                                var folder = response.result.files.find(
                                  (obj) => obj.name === date
                                );
                                if (!isEmpty(folder)) {
                                  localStorage.setItem("folder_id", folder.id);
                                } else {
                                  var fileMetadata = {
                                    name: date,
                                    mimeType:
                                      "application/vnd.google-apps.folder",
                                    parents: [
                                      localStorage.getItem("root_folder"),
                                    ],
                                  };
                                  window.gapi.client.drive.files
                                    .create({
                                      resource: fileMetadata,
                                    })
                                    .then(function(response) {
                                      switch (response.status) {
                                        case 200:
                                          var file = response.result;
                                          localStorage.setItem(
                                            "folder_id",
                                            file.id
                                          );
                                          break;
                                        default:
                                          break;
                                      }
                                    });
                                }
                              },
                              function(err) {}
                            );
                        } else {
                          var fileMetadata = {
                            name: "ui_images",
                            mimeType: "application/vnd.google-apps.folder",
                          };
                          window.gapi.client.drive.files
                            .create({
                              resource: fileMetadata,
                            })
                            .then(function(response) {
                              switch (response.status) {
                                case 200:
                                  var file = response.result;
                                  localStorage.setItem("root_folder", file.id);
                                  window.gapi.client.drive.files
                                    .list({
                                      q:
                                        "mimeType='application/vnd.google-apps.folder'",
                                    })
                                    .then(
                                      function(response) {
                                        var folder = response.result.files.find(
                                          (obj) => obj.name === date
                                        );
                                        if (!isEmpty(folder)) {
                                          localStorage.setItem(
                                            "folder_id",
                                            folder.id
                                          );
                                        } else {
                                          var fileMetadata = {
                                            name: date,
                                            mimeType:
                                              "application/vnd.google-apps.folder",
                                            parents: [
                                              localStorage.getItem(
                                                "root_folder"
                                              ),
                                            ],
                                          };
                                          window.gapi.client.drive.files
                                            .create({
                                              resource: fileMetadata,
                                            })
                                            .then(function(response) {
                                              switch (response.status) {
                                                case 200:
                                                  var file = response.result;
                                                  localStorage.setItem(
                                                    "folder_id",
                                                    file.id
                                                  );
                                                  break;
                                                default:
                                                  break;
                                              }
                                            });
                                        }
                                      },
                                      function(err) {}
                                    );
                                  break;
                                default:
                                  break;
                              }
                            });
                        }
                      },
                      function(err) {}
                    );
                },
                function(err) {}
              );
          },
          function(err) {}
        );
    });
  };

  CheckFieldBelonging = (field_id) => {
    var found = this.state.fields.filter((obj) => obj.name === field_id);
    if (!isEmpty(found)) {
      return true;
    } else {
      return false;
    }
  };

  GetFieldFromQR = (QR) => {
    var QRArray = QR.split(";");
    var do_belong = this.CheckFieldBelonging(QRArray[QRArray.length - 1]);
    return do_belong;
  };

  GetFieldIDFromQR = (QR) => {
    if (!isEmpty(QR)) {
      var QRArray = QR.split(";");
      return QRArray[QRArray.length - 1];
    } else {
      return "";
    }
  };

  CheckFieldEndDateFromFile = (file) => {
    var field_id = this.GetFieldIDFromQR(file.QR);
    var existing_field = this.state.fields_to_be_updated.filter(
      (obj) => obj === field_id
    );
    if (isEmpty(existing_field)) {
      var QR_obj = this.state.fields.filter((obj) => obj.name === field_id);

      var end_date = moment(QR_obj[0].end_date);
      if (end_date < previous_date) {
        toast.error(`Update Field ${field_id} End Date first!`);
        this.setState({ update_required: true });
        var fields_to_be_updated = this.state.fields_to_be_updated;
        if (isEmpty(fields_to_be_updated)) {
          fields_to_be_updated = [field_id];
        } else {
          fields_to_be_updated.push(field_id);
        }
        this.setState({ fields_to_be_updated });
      }
    }
  };

  componentWillMount() {
    this.GetFields();
    // this.GoogleLogin();
    this.GetSizes();
  }

  handleSelect(ranges) {
    start_date_str = ranges.selection.startDate;
    end_date_str = ranges.selection.endDate;
  }

  CheckFieldsDate = () => {
    var new_fields_list = [];
    var fields = this.state.fields_to_be_updated;
    for (const field of fields) {
      var obj = this.state.fields.filter((field) => field.name === field);
      if (moment(obj.end_date) < previous_date) {
        toast.error(`Update Field ${field} End Date first!`);
        if (isEmpty(new_fields_list)) {
          new_fields_list = [field];
        } else {
          new_fields_list.push(field);
        }
      }
    }
    if (isEmpty(new_fields_list)) {
      this.setState({
        fields_to_be_updated: [],
        open_modal: true,
        show_resume_button: false,
        update_required: false,
      });
      return true;
    } else {
      this.setState({ fields_to_be_updated: new_fields_list });
      return false;
    }
  };

  ResumeUploads = async () => {
    this.setState({
      open_modal: true,
    });
    await this.GetFields();
    var proceed = this.CheckFieldsDate();
    if (proceed) {
      toast.success("Resuming Upload!");
      // this.UploadFilesToDrive();
      this.UploadFilesToServer();
    } else {
      toast.error(
        "Please update end date of the highlighted fields and then click 'Resume Button'!"
      );
    }
  };

  CheckGDriveEmail = async () => {
    if (!isEmpty(localStorage.getItem("google_drive_email"))) {
      var res = await axios.get(`${URL}/user/my_data`);
      if (isEmpty(res.data.user.ui_g_drive_email)) {
        var proceed = await this.SaveGDriveEmail(
          localStorage.getItem("google_drive_email")
        );
        return proceed;
      } else {
        if (
          res.data.user.ui_g_drive_email ===
          localStorage.getItem("google_drive_email")
        ) {
          return true;
        } else {
          toast.error(
            `Please log in to your google drive with ${res.data.user.ui_g_drive_email} !`
          );
          return false;
        }
      }
    } else {
      console.log("Empty");
      return true;
    }
  };

  SaveGDriveEmail = async (email) => {
    try {
      var res = await axios.post(`${URL}/user/save_g_drive_email`, {
        email,
      });
      if (res.status === 200) {
        return true;
      } else {
        toast.error("This google drive email belongs to another user!");
        return false;
      }
    } catch (e) {
      toast.error(
        "This google drive email belongs to another user! Please reload and log into google drive with another email!"
      );
      return false;
    }
  };

  render() {
    return (
      <React.Fragment>
        <Dialog
          fullWidth
          maxWidth="lg"
          open={this.state.open_modal}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Uploading files ({this.state.uploaded_files}/
            {this.state.total_files})
          </DialogTitle>
          <DialogContent>
            <Table responsive>
              <thead>
                <tr>
                  <th width="20%">#</th>
                  <th width="50%">Name #</th>
                  <th width="30%">Status</th>
                </tr>
              </thead>
              <tbody>
                {this.state.data ? (
                  this.state.data.map((obj, k) => (
                    <tr key={k}>
                      <td>{k + 1}</td>
                      <td>{obj.name}</td>
                      <td>{obj.status}</td>
                    </tr>
                  ))
                ) : (
                  <h3>Loading...</h3>
                )}
              </tbody>
            </Table>
          </DialogContent>
          <DialogActions>
            {this.state.uploading === false ? (
              <Button color="primary" onClick={() => this.OpenDialog(false)}>
                Close
              </Button>
            ) : null}
          </DialogActions>
        </Dialog>
        <div className="row">
          <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 col-xs-12">
            <Card>
              <CardHeader
                title="Uploader"
                icon={
                  <i
                    className="flaticon-upload-1 mr-3 "
                    style={{ fontSize: "30px", color: "#3F4254" }}
                  ></i>
                }
              ></CardHeader>
              <CardBody>
                <Form.Group>
                  <Form.Label>Select a Folder</Form.Label>
                  <input
                    directory=""
                    webkitdirectory=""
                    className="form-control form-control-sm"
                    type="file"
                    name="image"
                    ref={this.myRef}
                    // onChange={(e) => this.UploadFiles(e)}
                    onChange={(e) => this.UploadFilesServer(e)}
                    onClick={(e) => (e.currentTarget.value = null)}
                  ></input>
                </Form.Group>
              </CardBody>{" "}
              {this.state.show_resume_button && (
                <CardFooter>
                  <Button color="primary" onClick={this.ResumeUploads}>
                    Resume Upload
                  </Button>
                </CardFooter>
              )}
            </Card>
          </div>
          <canvas id="canvas" style={{ display: "none" }}></canvas>
          <img
            id="img"
            alt="img"
            style={{ display: "none" }}
            crossOrigin="anonymous"
          />
          <div className="col-xl-8 col-lg-7 col-md-6 col-sm-12 col-xs-12">
            <Card>
              <CardHeader
                title="Field Dates"
                icon={
                  <i
                    className="flaticon-calendar-1 mr-3 "
                    style={{ fontSize: "30px", color: "#3F4254" }}
                  ></i>
                }
              ></CardHeader>
              <CardBody>
                <Select
                  name="fields"
                  options={this.state.fields}
                  onChange={this.FieldChanges}
                  value={this.state.fields.filter(
                    ({ id }) => id === this.state.field_id
                  )}
                  getOptionLabel={(obj) => obj.name}
                  getOptionValue={({ id }) => id}
                />
                <div className="col-12 mt-5">
                  <DateRangePicker
                    ranges={[
                      {
                        startDate: this.state.start_date,
                        endDate: this.state.end_date,
                        key: "selection",
                      },
                    ]}
                    onChange={async (ranges) => {
                      await this.handleSelect(ranges);
                      await this.setState({
                        start_date: new Date(start_date_str),
                        end_date: new Date(end_date_str),
                      });
                    }}
                  />
                </div>

                {this.state?.field_id !== "0" ? (
                  <div>
                    <label>
                      <b>{this?.state?.field_name}</b>
                      {";  "}
                      {Number(this?.state?.start_date.getMonth()) +
                        1 +
                        "-" +
                        this?.state?.start_date.getDate() +
                        "-" +
                        this?.state?.start_date.getFullYear()}{" "}
                      {"  to  "}
                      {Number(this?.state?.end_date.getMonth()) +
                        1 +
                        "-" +
                        this?.state?.end_date.getDate() +
                        "-" +
                        this?.state?.end_date.getFullYear()}
                    </label>
                    <button
                      className="btn btn-sm btn-primary "
                      style={{ float: "right" }}
                      onClick={this.updateDate}
                    >
                      Save Date Range
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </CardBody>
            </Card>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Uploader);
